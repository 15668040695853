import React from 'react'
import * as packageInfo from '../../../package.json'
import { Page } from '../../components'
import "./about.css"

const { name, version } = packageInfo
export const About: React.FC = () => {
  return (
    <Page title={'About This App'}>
      <div className="singleLine">
        <label className="text" htmlFor="appName">
          {`Name:`}
        </label>
        <input
          className="input"
          type="text"
          readOnly={true}
          id="appName"
          value={name}
        />
      </div>
      <div className="singleLine">
        <label className="text" htmlFor="appVersion">
          {`Version:  `}
        </label>
        <input
          className="input"
          type="text"
          readOnly={true}
          id="appVersion"
          value={version}
        />
      </div>
    </Page>
  )
}
